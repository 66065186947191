import gql from "graphql-tag";

export const GET_SETTINGS = gql`
query Settings {
  meUser {
    settings {
      id
      _id
      setting
      value
    }
  }
  productCategories {
    _id
    sortOrder
    name
    mainCategory {
      _id
    }
  }
  productMainCategories {
    _id
    sortOrder
    name
    subCategories {
      _id
      sortOrder
      name
    }
  }
}  
`

export const SAVE_SETTING = gql`
mutation SaveSetting($label: String!, $value: String!)
{
  createChangeSetting(input:{label: $label, value: $value}) {
    changeSetting {
      response
    }
  }
}
`

export const CREATE_CATEGORY_SORT_ORDER_CHANGE = gql`
mutation CreateCategorySortOrderChange($changes: String!)
{
  createChangeCategorySortOrder(input: {changes: $changes}) {
    changeCategorySortOrder {
      response
    }
  }
}
`
