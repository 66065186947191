<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Setari platforma" subtitle="Configureaza platforma">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Setari platforma</b-breadcrumb-item>
          <b-breadcrumb-item active>Configurare</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <!-- Latest Customers -->
        <b-col xl="4">
          <base-block title="Limita pentru transport gratuit" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="8" offset-lg="2">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <b-form @submit.stop.prevent="saveLimitForm">
                      <div class="form-group">
                        <b-input-group>
                          <b-form-input size="md" class="form-control-alt" placeholder="Limit" v-model="$v.transportLimitForm.transportLimit.$model" :state="!$v.transportLimitForm.transportLimit.$error && null" aria-describedby="tax-feedback" @blur="removeServerErrors('transportLimitForm')" @input="removeServerErrors('transportLimitForm')"></b-form-input>
                          <b-input-group-append>
                            <b-button type="submit" size="md" variant="primary">Salvare</b-button>
                          </b-input-group-append>
                          <b-form-invalid-feedback v-if="!$v.transportLimitForm.transportLimit.required" id="limit-feedback">
                            Campul este obligatoru!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="!$v.transportLimitForm.transportLimit.decimal" id="limit-feedback">
                            Valoarea trebuie sa fie numerica!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.transportLimitForm.tax" v-text="errors.transportLimitForm.transportLimit" id="limit-feedback"></b-form-invalid-feedback>
                        </b-input-group>
                      </div>
                    </b-form>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <b-col xl="4">
          <base-block title="TVA" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="8" offset-lg="2">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <b-form @submit.stop.prevent="saveTaxForm">
                      <div class="form-group">
                        <b-input-group>
                          <template #prepend>
                            <b-input-group-text size="md" class="input-group-text-alt">%</b-input-group-text>
                          </template>
                          <b-form-input size="md" class="form-control-alt" placeholder="TVA" v-model="$v.taxForm.tax.$model" :state="!$v.taxForm.tax.$error && null" aria-describedby="tax-feedback" @blur="removeServerErrors('taxForm')" @input="removeServerErrors('taxForm')"></b-form-input>
                          <b-input-group-append>
                            <b-button type="submit" size="md" variant="primary">Salvare</b-button>
                          </b-input-group-append>
                          <b-form-invalid-feedback v-if="!$v.taxForm.tax.required" id="tax-feedback">
                            Campul este obligatoru!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="!$v.taxForm.tax.numeric" id="tax-feedback">
                            Valoarea trebuie sa fie numerica!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.taxForm.tax" v-text="errors.taxForm.tax" id="tax-feedback"></b-form-invalid-feedback>
                        </b-input-group>
                      </div>
                    </b-form>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <b-col xl="4">
          <base-block title="Pretul transportului" header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="8" offset-lg="2">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <b-form @submit.stop.prevent="savePriceForm">
                      <div class="form-group">
                        <b-input-group>
                          <b-form-input size="md" class="form-control-alt" placeholder="Pretul transportului" v-model="$v.transportPriceForm.transportPrice.$model" :state="!$v.transportPriceForm.transportPrice.$error && null" aria-describedby="tax-feedback" @blur="removeServerErrors('transportPriceForm')" @input="removeServerErrors('transportPriceForm')"></b-form-input>
                          <b-input-group-append>
                            <b-button type="submit" size="md" variant="primary">Salvare</b-button>
                          </b-input-group-append>
                          <b-form-invalid-feedback v-if="!$v.transportPriceForm.transportPrice.required" id="tax-feedback">
                            Campul este obligatoru!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="!$v.transportPriceForm.transportPrice.decimal" id="tax-feedback">
                            Valoarea trebuie sa fie numerica!
                          </b-form-invalid-feedback>
                          <b-form-invalid-feedback v-if="errors.transportPriceForm.transportPrice" v-text="errors.transportPriceForm.transportPrice" id="tax-feedback"></b-form-invalid-feedback>
                        </b-input-group>
                      </div>
                    </b-form>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
        <b-col xl="12">
          <base-block title="Ordinea sortării categoriilor " header-bg content-full>
            <b-row class="row-deck">
              <b-col xl="12">
                <base-block header-bg>
                  <div class="font-size-sm push">
                    <draggable class="row row-deck" v-bind:list="mainCategories" tag="div" @start="drag = true" @end="drag = false" @change="onCategorySortOrderUpdate('_id_', 'mainCategory')">
                      <div class="col-lg-2 pointer-element" v-for="(element, index) in mainCategories" :key="element._id">
                        <div class="flex-fill mb-2 list-group-item">
                          <b-alert variant="primary" show>
                            <span class="w-10 d-inline-block">{{ getSortString(index + 1) }}</span> {{ element.name }}
                          </b-alert>
                          <draggable v-bind:list="element.subCategories" tag="div" @start="drag = true" @end="drag = false" @change="onCategorySortOrderUpdate(element._id, 'subCategory')">
                            <div class="pointer-element" v-for="(subElement, subIndex) in element.subCategories" :key="subElement._id">
                              <div class="flex-fill mb-2 list-group-item">
                                <span class="w-10 d-inline-block">{{ getSortString(subIndex + 1) }}.</span> {{ subElement.name }}
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </base-block>
              </b-col>
            </b-row>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {CREATE_CATEGORY_SORT_ORDER_CHANGE, GET_SETTINGS, SAVE_SETTING} from "@/constants/setting-graphql";
import {fleshErrorMessage} from "@/error/server-error";
import {validationMixin} from "vuelidate";
import {required, numeric, decimal} from "vuelidate/lib/validators";
import draggable from "vuedraggable";

export default {
  name: "Settings",
  mixins: [validationMixin],
  components: {draggable},
  data () {
    return {
      taxForm: {
        tax: null
      },
      transportLimitForm: {
        transportLimit: null
      },
      transportPriceForm: {
        transportPrice: null
      },
      errors: {
        taxForm: {},
        transportLimitForm: {},
        transportPriceForm: {},
      },
      categories: [],
      mainCategories: [],
      drag: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  validations: {
    taxForm: {
      tax: {
        required,
        numeric
      }
    },
    transportLimitForm: {
      transportLimit: {
        required,
        decimal
      }
    },
    transportPriceForm: {
      transportPrice: {
        required,
        decimal
      }
    }
  },
  apollo: {
    meUser: {
      query: GET_SETTINGS,
      fetchPolicy: 'no-cache',
      result (result) {
        let parent = this;
        result.data.meUser.settings.forEach(function (item) {
          if ('TAX_PERCENTAGE' === item.setting) {
            parent.taxForm.tax = item.value;
          }
          if ('FREE_TRANSPORT_LIMIT' === item.setting) {
            parent.transportLimitForm.transportLimit = item.value;
          }
          if ('TRANSPORT_PRICE' === item.setting) {
            parent.transportPriceForm.transportPrice = item.value;
          }
        });
        this.mainCategories = result.data.productMainCategories;
      },
      error (error) {
        fleshErrorMessage(error, this);
      }
    }
  },
  methods: {
    removeServerErrors(form) {
      this.errors[form] = {};
    },
    saveTaxForm() {
      this.errors.taxForm = {};
      this.$v.taxForm.$touch()
      if (this.$v.taxForm.$anyError) {
        return;
      }
      this.saveSetting('TAX_PERCENTAGE', this.$v.taxForm.tax.$model);
    },
    saveLimitForm() {
      this.errors.transportLimitForm = {};
      this.$v.transportLimitForm.$touch()
      if (this.$v.transportLimitForm.$anyError) {
        return;
      }
      this.saveSetting('FREE_TRANSPORT_LIMIT', this.$v.transportLimitForm.transportLimit.$model);
    },
    savePriceForm() {
      this.errors.transportPriceForm = {};
      this.$v.transportPriceForm.$touch()
      if (this.$v.transportPriceForm.$anyError) {
        return;
      }
      this.saveSetting('TRANSPORT_PRICE', this.$v.transportPriceForm.transportPrice.$model);
    },
    saveSetting(label, value) {
      this.$apollo.mutate({
        mutation: SAVE_SETTING,
        variables: {
          'label': label,
          'value': value
        }
      }).then(() => {
        this.flashMessage.info({message: "Modificarea este salvata cu succes!"});
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    onCategorySortOrderUpdate (id, type) {
      let mutationValue = [];
      this.mainCategories.forEach(function(value, mainIndex) {
        if ('subCategory' === type && value._id === id) {
          value.subCategories.forEach(function (subValue, index) {
            mutationValue.push({'id': subValue._id, 'sortOrder': index, 'type': 'subCategory'});
          });
        }
        if ('mainCategory' === type) {
          mutationValue.push({'id': value._id, 'sortOrder': mainIndex, 'type': 'mainCategory'});
        }
      });
      this.$apollo.mutate({
        mutation: CREATE_CATEGORY_SORT_ORDER_CHANGE,
        variables: {
          'changes': JSON.stringify(mutationValue),
        }
      }).then(() => {
        this.flashMessage.info({message: "Ordinea categoriilor este salvata cu succes!"});
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getSubcategories(mainCategoryId) {
      let categories = [];
      this.categories.forEach(function (item) {
        if (item.mainCategory._id === mainCategoryId) {
          categories.push(item);
        }
      });

      return categories;
    },
    getSortString(index)
    {
      return index < 0 ? 'nbsp;'+index : index;
    }
  }
}
</script>